import * as React from "react";
import Box from "@mui/material/Box";


export default function Team() {
    return (
        <>
            <p>Blog</p>
        </>
    );
}
