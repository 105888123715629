import * as React from "react";
import Box from "@mui/material/Box";
import ShowBookList from "./ShowBookList";


export default function Training() {
    return (
        <Box >
            <ShowBookList/>
        </Box>
    );
}
