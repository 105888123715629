import React, { Fragment, Component } from 'react';
import MediaQuery from 'react-responsive';

class MyMediaQuery extends Component {
    render() {
        return (
            <Fragment>
                <div>Device Test!  {JSON.stringify(this.props.host)}</div>
                <div>{this.props.ipAddress}</div>

                <MediaQuery minDeviceWidth={1224}>
                    <div>You are a desktop or laptop</div>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={1224}>
                    <div>You are a tablet or mobile phone</div>
                </MediaQuery>

                <MediaQuery orientation='portrait'>
                    <div>You are portrait</div>
                </MediaQuery>
                <MediaQuery orientation='landscape'>
                    <div>You are landscape</div>
                </MediaQuery>

                <MediaQuery minResolution='2dppx'>
                    <div>You are retina</div>
                </MediaQuery>
            </Fragment>
        );
    }
}

export default MyMediaQuery;
