import React from 'react';

const HiSysLogo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
            <g fill-rule="evenodd">
                <circle cx="50" cy="50" r="50" fill="#f0f0f0" fill-opacity="80%"/>
                <path d="M97.32 66.19a50 50 0 0 1-58.63 32.52l10.54-34.28z" fill="#1b807d" fill-opacity="90%"/>
                <path d="M68 3.35a50 50 0 0 1 31.43 54.21l-47.55-1.74z" fill="#207fc0" fill-opacity="90%"/>
                <path d="M50 0a50.28 50.28 0 0 1 9.65.93L30.42 96A50 50 0 0 1 50 0z" fill="#eb8323" fill-opacity="90%"/>
            </g>
        </svg>
    )
}


export default HiSysLogo
