import * as React from "react";
import Box from "@mui/material/Box";


export default function Matches() {
    return (
        <Box >
            <p>Products</p>
        </Box>
    );
}
