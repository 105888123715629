import React, {Component} from 'react';
import {Routes, Route} from 'react-router-dom';
import './App.css';

import Body from "./components/Body";
import Team from "./components/Team";
import Matches from "./components/Matches";
import Training from "./components/Training";
import Footer from "./components/Footer";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import CreateBook from "./components/CreateBook";
import 'react-notifications/lib/notifications.css';
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import Spinner from "./components/Spinner";
import Box from "@mui/material/Box";
import {Image} from "@mui/icons-material";
import {createTheme, ThemeProvider, useMediaQuery} from "@mui/material";
import ToggleColorMode from "./ToggleColorMode";
import MyMediaQuery from "./components/MyMediaQuery";
import axios from 'axios';


class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ipAddress: null,
            colorMode: "light",
            theme : createTheme({
                palette: {
                    mode: "light",
                },
            })
        };
    }

    host = {protocol: window.location.protocol, location: window.location.host};


    mode = "light";

    toggleTheme = () => {
        //this.setState(colorMode => { return {colorMode : (colorMode === 'light' ? 'dark' : 'light')}});
    }

    componentDidMount() {
        axios.get(`https://api.ipify.org?format=json`)
            .then(res => {
                const ipAddress = res.data.ip || "0.0.0.0";
                //alert(JSON.stringify(ipAddress));
                this.setState({ ipAddress });
            })
    }
    //<ThemeProvider theme={theme}>
    render() {
        if (this.state.ipAddress === "77.170.163.52") {
            return (
                <ThemeProvider theme={this.state.theme}>
                    <ResponsiveAppBar user={{name: "JR", avatar: "/images/avatar/3.jpg", club: "Meppeler HV", logo : ""}}
                                      host={this.host} toggleTheme={this.toggleTheme} />

                    <MyMediaQuery host={this.host}/>
                    <Box className="App"
                         sx={{
                             //backgroundImage: `url("./images/background/bg.jpg")`,
                             objectFit: "cover",
                             pointerEvents: "none",
                             position: "fixed",
                             left: "0",
                             top: "0",
                             height: "100%",
                             width: "100%",
                             zIndex: "-1"
                         }}
                    >
                        <Box sx={{pb: 7, pt: 10}}>
                            <Routes>
                                <Route path="/" element={<Body/>}/>
                                <Route path="/matches" element={<Matches/>}/>
                                <Route path="/training" element={<Training/>}/>
                                <Route path="/team" element={<Team/>}/>
                            </Routes>
                            <NotificationContainer/>
                        </Box>
                    </Box>
                    <Footer/>
                </ThemeProvider>
            )
        }
        else {
            return ( <ToggleColorMode />);
        }
    }
}

export default App;

