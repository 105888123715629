import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import ToggleColorMode from "./ToggleColorMode";



// Before
//import { render } from 'react-dom';
//const container = document.getElementById('app');
//render(<App tab="home" />, container);

// After
const container = document.getElementById('root');
const root = createRoot(document.getElementById('root')); // createRoot(container!) if you use TypeScript
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>
);


/*ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById("root")
);

 */
