import React, { useState, useEffect } from 'react';
import '../App.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BookCard from './BookCard';
import Spinner from './Spinner';

function ShowBookList() {

    let [books, setBooks] = useState([]);
    let [loading, setLoading] = useState(true);

    useEffect(() => {
        axios
            .get('/books')
            .then(res => {
                setBooks(res.data);
//                setLoading(false);
            })
            .catch(err =>{
                console.log('Error from ShowBookList');
            })
    }, []);

    let listContent;

    if(loading) {
        listContent = <div className="list-msg"><Spinner/></div>;
    }
    else if(books.length === 0) {
        listContent = <div className="list-msg">ℹ️ No books added. Click the 'Add New Book' button to add one.</div>;
    }
    else {
        listContent = books.map((book, k) =>
            <BookCard book={book} key={k} />
        );
    }

    return (
        <div className="ShowBookList">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <br />
                        <h2 className="display-4 text-center">Book List</h2>
                    </div>

                    <div className="col-md-11">
                        <Link to="/create-book" className="btn btn-outline-warning float-right">
                            + Add New Book
                        </Link>
                        <br />
                        <br />
                        <hr />
                    </div>

                </div>

                <div className="list">
                    { listContent }
                </div>
            </div>
        </div>
    );
}

export default ShowBookList;
