import react, {Component} from 'react';


class BookCard extends Component {
    render(){
        return (
            <div>
                <h2>Book Card</h2>
            </div>
        )
    }
}

export default BookCard;
