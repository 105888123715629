import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import SportsHockeyOutlinedIcon from '@mui/icons-material/SportsHockeyOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
export default function Footer() {
    const [value, setValue] = React.useState(0);
    const ref = React.useRef(null);
//    const [messages, setMessages] = React.useState(() => refreshMessages());

/*    React.useEffect(() => {
        ref.current.ownerDocument.body.scrollTop = 0;
        Body.setMessages(refreshMessages());
    }, [value, setMessages]);
*/
    return (
        <Box sx={{ pb: 7 }} ref={ref}>
            <CssBaseline />

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                >
                    <BottomNavigationAction label="Home" icon={<HomeOutlinedIcon />} />
                    <BottomNavigationAction label="Team" icon={<Groups2OutlinedIcon />} />
                    <BottomNavigationAction label="Club" icon={<SportsHockeyOutlinedIcon />} />
                    <BottomNavigationAction label="Profiel" size="large" icon={<AccountCircleOutlinedIcon />} />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}

